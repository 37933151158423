import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Translate } from "./Translator"

import ActionCallPng from '../Assets/action_call.png';
import ActionCallHoverPng from '../Assets/action_call_hover.png';

const ImageMakeCall = (props) => {

    const [mMouseOver, SetMouseOver] = useState(false)

    const TextHint = props.ContactName ? (props.ContactName + ", " + Translate("Call") + " " + props.ContactNumber) : Translate("Call") + " " + props.ContactNumber
    return (
        <img alt="" src={mMouseOver ? ActionCallHoverPng : ActionCallPng} className="action" onMouseEnter={e => SetMouseOver(true)} onMouseLeave={e => SetMouseOver(false)} onClick={() => props.MakeCallEvent()} width="14" height="14" title={TextHint}/>
    )
}

ImageMakeCall.propTypes = {
    ContactName: PropTypes.string,
    ContactNumber: PropTypes.string,
    MakeCallEvent: PropTypes.func
}

export default ImageMakeCall;