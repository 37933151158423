import axios from "axios";

const cSignInBaseApi = "https://signin-va.cloudcti.nl/signin/api/";

export async function SigninApiTokenGet(aMicrosoftToken, aUsername, aPassword, aSignInMethod) 
{
    return await axios.post(
        cSignInBaseApi + "teamstoken",  
        {
            SigninMethod: aSignInMethod,
            CloudCTIUsername: aUsername,
            CloudCTIPassword: aPassword,
        },
        {
            headers:
            {
                Authorization: "Bearer " + aMicrosoftToken,
            },
        }
    )
}

export async function SigninApiTokenRefresh(aMicrosoftToken, aCloudCTIToken) 
{
    return await axios.post(
        cSignInBaseApi + "teamstokenrefresh",  
        {
            MicrosoftToken: aMicrosoftToken
        },
        {
            headers:
            {
                Authorization: "Bearer " + aCloudCTIToken,
            },
        }
    )
}