import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Button } from "react-bootstrap"
import { SigninApiTokenGet } from "../ExternalApi/SigninApi"
import { PushApiSignIn } from "../ExternalApi/PushApi"
import { Translate } from "./Translator"
import { DarkBackground } from "./GeneralFunctions"

import "../css/Login.css";

import CloudCtiPng from '../Assets/CloudCTI.png';
import UserLightBgPng from '../Assets/user_lightbg.png';
import UserDarkBgPng from '../Assets/user_darkbg.png';
import PasswordLightBgPng from '../Assets/password_lightbg.png';
import PasswordDarkBgPng from '../Assets/password_darkbg.png';

const Login = (props) => {

  const [mUser, SetUser] = useState("")
  const [mPassword, SetPassword] = useState("")
  const [mErrorMessage, SetErrorMessage] = useState(props.Error)

  const SignIn = async (aUsername,aPassword,aSigninMethod) => {

    try 
    {
        var signinResult = await SigninApiTokenGet(props.MicrosoftToken,aUsername,aPassword,aSigninMethod)
        if (signinResult.data)
        {
            if (signinResult.status === 200)
            {
                if (signinResult.data.SecurityToken)
                {
                    const error = await PushApiSignIn(
                        signinResult.data.SecurityToken, 
                        props.Teams.context.user.id, 
                        (props.Teams.context.tenant ? props.Teams.context.tenant.id : ""), 
                        props.Teams.context.user.userPrincipalName)
                    if (!error)
                    {
                        window.location.reload()
                    }
                    else
                    {
                        SetErrorMessage(Translate("Failed to link to Teams") + ": " + error);
                    }
                }
            }
            else
            {
                let error = "";
                try { error = signinResult.data.Exception; } catch {}
                SetErrorMessage(error === "" ? Translate("An error has occurred") : error);
            }
        }
        else
        {
            SetErrorMessage(Translate("An error has occurred"));            
        }
    } 
    catch (ex) 
    {    
        let error = ex;
        try { error = ex.response.data.Exception; } catch {}
        SetErrorMessage(error === "" ? Translate("An error has occurred") : error);
    }
  }
  
  const mBackgroundColor = props.Teams.theme.colorNeutralBackground1
  
  return (   
    <table className="main">
        <tbody>
            <tr>
                <td align="center" valign="center" className="errormessage">
 
                    <table width="400" className="login" cellPadding="12">
                        <tbody>
                            <tr>
                                <td align="center"><img alt="" src={CloudCtiPng}/></td>
                            </tr>
                            <tr>
                                <td align="center" className="header">{Translate("Enter your CloudCTI credentials")}</td>
                            </tr>
                            <tr>
                                <td>

                                    <table cellPadding="1" width="95%">
                                    <tbody>
                                        <tr height="8" className="bordertop"/>
                                        <tr>
                                            <td>
                                                <img alt="" src={(DarkBackground(mBackgroundColor) ? UserDarkBgPng : UserLightBgPng)} width="20" height="20" className="up2px"/> &nbsp; <input className="signininputfield" type="text" value={mUser} placeholder={Translate("Email / Phone")} onChange={evt => SetUser(evt.target.value)}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img alt="" src={(DarkBackground(mBackgroundColor) ? PasswordDarkBgPng : PasswordLightBgPng)} width="20" height="20" className="up2px"/> &nbsp; <input className="signininputfield" type="password" value={mPassword} placeholder={Translate("Password")} onChange={evt => SetPassword(evt.target.value)} onKeyPress={(evt) => { if (evt.key === 'Enter') { SignIn(mUser,mPassword,"")}}}/>
                                            </td>
                                        </tr>
                                        {mErrorMessage ? <tr className="errormessage"><td align="center">{mErrorMessage}</td></tr> : <tr height="0"/>}
                                    </tbody>
                                    </table>

                                </td>
                            </tr>
                            <tr>
                                <td align="center"><Button variant="primary" size="sm" onClick={() => SignIn(mUser,mPassword,"")} className="signinbutton">{Translate("Sign In")}</Button></td>
                            </tr>
                            <tr>
                                <td align="center">{Translate("or")}</td>
                            </tr>
                            <tr>
                                <td align="center"><Button variant="info" size="sm" onClick={() => SignIn("","","Microsoft")} className="signinbutton">{Translate("Authorize with Microsoft")}</Button></td>                                
                            </tr>
                        </tbody>
                    </table>

                </td>
            </tr>
        </tbody>
    </table>     
  );
}

Login.propTypes = {
    Teams: PropTypes.object,
    MicrosoftToken: PropTypes.string
}
  

export default Login;