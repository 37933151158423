import React, { useState } from "react"
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import Button from 'react-bootstrap/Button';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { DarkBackground } from "./GeneralFunctions"
import { Translate } from "./Translator"

import ActionCallSmallLightBgPng from '../Assets/action_call_small_lightbg.png'
import ActionCallSmallDarkBgPng from '../Assets/action_call_small_darkbg.png'
import ActionCallInboundLightBgPng from '../Assets/call_inbound_lightbg.png'
import ActionCallInboundDarkBgPng from '../Assets/call_inbound_darkbg.png'
import ActionCallOutboundLightBgPng from '../Assets/call_outbound_lightbg.png'
import ActionCallOutboundDarkBgPng from '../Assets/call_outbound_darkbg.png'

import ActionMultiplePng from '../Assets/action_multiple.png'

const ContactCall = (props) => {

    const [mMouseOver, SetMouseOver] = useState(false)
    const [mRepaint, SetRepaint] = useState(false)

    const ImageUrl = "https://downloadcloudcti.blob.core.windows.net/files/WebRCT/Applications/" + props.Application + ".png"
    
    const mBackgroundColor = props.TeamsTheme.colorNeutralBackground1

    const PerformScreenpop = (aScreenpopUrl) => {
        window.open(aScreenpopUrl)
        SetRepaint(!mRepaint)
    }

    props.Scripts = props.Scripts === null ? [] : props.Scripts

    return (
        <table className="contact" width="100%" onMouseEnter={e => SetMouseOver(true)} onMouseLeave={e => SetMouseOver(false)}>
        <tbody>
            <tr>
                <td width="4"/>
                <td width="40">{props.Application === "" ? <table width="32" height="32" className="unknowncrm"><tbody><tr><td align="center" valign="center">{props.Name ? props.Name[0] : "?"}</td></tr></tbody></table>  : <img alt="" src={ImageUrl} className="applicationpng" width="32" height="32"/>}</td>
                <td width="4"/>
                <td width="300" align="left">
                    {props.Name ? props.Name : Translate("Unknown contact") + ", " + props.Number}<br/>
                    <span>
                    <img className="up2px" alt="" src={props.Direction === "Incoming" ? (DarkBackground(mBackgroundColor) ? ActionCallInboundDarkBgPng : ActionCallInboundLightBgPng) : (DarkBackground(mBackgroundColor) ? ActionCallOutboundDarkBgPng : ActionCallOutboundLightBgPng)} width="12" height="12"/>
                    <span className="smalltext left2px"> {Translate(props.Direction)}</span> 
                    </span>
                </td>   
                {mMouseOver ? <td width="0"/> : <td width="*"/>}
                {mMouseOver ? <td width="0"/> : props.VisualMode == "FullMode" ? <td width="80" align="left" className="smalltext">{props.Duration}</td> : <td width="0"/>}
                {mMouseOver ? 
                    <td width="*" align="right">
                        <Popup trigger={<span className="up2px"><img alt="" src={ActionMultiplePng}  className="action"/></span>} arrow={false} closeOnDocumentClick={true} position="bottom left" keepTooltipInside="true">
                            <div onClick={() => props.MakeCallEvent(props.Number, props.Name, props.Application, props.ScriptName, props.ScreenpopUrl)} className="popupmenuitem"><span className="popupmenuitemtext">{Translate("Call back")}</span></div>    
                            <div className="popupmenuitem"><CopyToClipboard text={props.Number}><span className="popupmenuitemtext">{Translate("Copy number")}</span></CopyToClipboard></div>
                            
                            {props.Scripts.map((script,index) => (                        
                                script.ScreenpopUrl ? <div onClick={() => PerformScreenpop(script.ScreenpopUrl)} className="popupmenuitem"><span className="popupmenuitemtext">{script.Name}</span></div> : <span/> 
                            ))}
                            
                            <div onClick={() => props.OnDeleteFromCallList(props.Id)} className="popupmenuitem"><span className="popupmenuitemtext">{Translate("Remove from view")}</span></div> 

                            {props.AlreadySpeedDial ?  
                                <div onClick={() => props.OnRemoveFromSpeedDial(props.Number)} className="popupmenuitem"><span className="popupmenuitemtext">{Translate("Remove from speed dial")}</span></div> :
                                <div onClick={() => props.OnAddToSpeedDial(props.Number, props.Name, props.Application, props.ScriptName, props.ScreenpopUrl)} className="popupmenuitem"><span className="popupmenuitemtext">{Translate("Add to speed dial")}</span></div>}                           
                        </Popup>

                        &nbsp;&nbsp;

                        <span className="up2px"><Button variant="light" className="callbutton action" onClick={() => props.OnMakeCall(props.Number, props.Name, props.Application, props.Scripts)}><img alt="" src={ActionCallSmallLightBgPng} className="up5px"/>&nbsp; <span className="up3px">{Translate("Call")}</span></Button></span>
                    </td> : 
                    <td width="120" align="right" className="smalltext">{props.Time}</td>}
                <td width="4"/>
            </tr>
        </tbody>
        </table>
    )
}

ContactCall.propTypes = {
    Id: PropTypes.string,
    Number: PropTypes.string,
    Name: PropTypes.string,
    Application: PropTypes.string,
    Direction: PropTypes.string,
    Scripts: PropTypes.array,
    Time: PropTypes.string,
    Duration: PropTypes.string,
    AlreadySpeedDial: PropTypes.bool,
    TeamsTheme: PropTypes.object,
    VisualMode: PropTypes.string,
    OnMakeCall: PropTypes.func,
    OnAddToSpeedDial: PropTypes.func,
    OnRemoveFromSpeedDial: PropTypes.func,
    OnDeleteFromCallList: PropTypes.func,
  }

export default ContactCall;