import React, { useState, useEffect } from "react"
import jwtDecode from 'jwt-decode'
import { useTeamsFx } from "@microsoft/teamsfx-react"
import { app as teamsjs } from "@microsoft/teams-js"
import { authentication as teamsjsauth } from "@microsoft/teams-js"
import axios from "axios"

import Main from "./Components/Main"
import Login from "./Components/Login"
import Logs from "./Components/Logs"

import { PushApiGetSignedInToken, PushApiSignIn, PushApiSignOut } from "./ExternalApi/PushApi"
import { SigninApiTokenRefresh } from "./ExternalApi/SigninApi"
import { SetLanguage, Translate } from "./Components/Translator"
import { Log } from "./ExternalApi/LogApi"

const App = (props) => {

  //BETA FLAG!!
  const cBeta = false
  
  const [mReload, SetReload] = useState(false)

  const [mMicrosoftToken, SetMicrosoftToken] = useState("")
  const [mCloudCTIToken, SetCloudCTIToken] = useState(null)
  const [mError, SetError] = useState("")
  const [mGetConsent, SetGetConsent] = useState(false)

  useEffect(() => {
    
    teamsjs.initialize().then(() => {
      getClientSideToken()
          .then((clientSideToken) => {
            localStorage.setItem("clienttoken",clientSideToken)
            return getServerSideToken(clientSideToken)
          })
          .catch((error) => {
            return SetError(error)
          })
        }) 

  }, []); 
  
  function getClientSideToken() {
    return new Promise((resolve, reject) => {        
        teamsjsauth.getAuthToken().then((result) => {
            resolve(result);
        }).catch((error) => {
            reject("Error getting microsoft token: " + error)
        });
    });
  }

  function getServerSideToken(clientSideToken) {
    localStorage.setItem("extralog3","getServerSideToken start")
    return new Promise((resolve, reject) => {
        teamsjs.getContext().then((context) => {
        
            localStorage.setItem("extralog3","getServerSideToken dopost")
            axios.post("https://teamsservercloudcti-app.azurewebsites.net/getSsoServerSideToken", 
            {
                tid: context.user.tenant.id,
                token: clientSideToken,
                isbeta: (cBeta ? "true" : "false")
            },
            {
                headers: {
                    ContentType: "application/json",
                    Accept: "application/json"
                }
            })
            .then((response) => {
              localStorage.setItem("extralog3","getServerSideToken " + response.data.accessToken)
              localStorage.setItem("servertoken",response.data.accessToken)
              SetMicrosoftToken(response.data.accessToken)
            })
            .catch((error) => {

              localStorage.setItem("extralog3","getServerSideToken error " + error)
                if (error.message.endsWith("401"))
                {
                  localStorage.setItem("extralog1","mGetConsent set")
                  SetGetConsent(true)
                }
                else
                {
                  console.log("cst=" + clientSideToken)
                  console.log("tid=" + context.user.tenant.id)
                  localStorage.setItem("extralog1","mGetConsent no")
                  SetError("Failed to generate Microsoft token: " + error.message + "; " + error.stack)
                }
            })

        });
    });
  } 

  async function ValidateTokenExpiration(aToken,aMicrosoftToken,aTeamContext) 
  {
    const Now = new Date()
    const DecodedToken = jwtDecode(aToken)

    //Check token expiration
    if (DecodedToken.exp * 1000 > (Now.getTime() + (7 * 24 * 60 * 60 * 1000))) 
    {
      //token is valid and will remain valid for at least 7 days
      return aToken
    }
    else if (DecodedToken.exp * 1000 > Now.getTime()) 
    {      
      //token will expire within 7 days
      try
      {
        const data = await SigninApiTokenRefresh(aToken,aMicrosoftToken)
        if (data && (data !== undefined) && data.SecurityToken && (data.SecurityToken !== undefined))
        {
          await PushApiSignIn(
            data.SecurityToken, 
            aTeamContext.context.user.id, 
            (aTeamContext.context.user.tenant ? aTeamContext.context.user.tenant.id : ""), 
            aTeamContext.context.user.userPrincipalName)
          return data.SecurityToken
        }
        else
        {
          return aToken
        }
      }
      catch
      {
        //Token is still valid
        return aToken
      } 
    }
    else
    {
      //Token has expired. Sign out user so you will no longer get events.
      await PushApiSignOut(aMicrosoftToken)
      return ""
    }
  } 

  function GetPhoneType(aToken) 
  {
    try
    {
      const DecodedToken = jwtDecode(aToken)
      const LicencePlatforms = DecodedToken.LicencePlatforms.split(",")
      return LicencePlatforms.includes("Teams") ? "Teams" : "CloudCTI"
    }
    catch 
    {
      return "CloudCTI"
    }
  }  

  function GetTenantGuid(aToken) 
  {
    try
    {
      const DecodedToken = jwtDecode(aToken)
      return DecodedToken.TenantGuid
    }
    catch 
    {
      return ""
    }
  }  

  function GetUserIsAdmin(aToken) 
  {
    const DecodedToken = jwtDecode(aToken)
    return (DecodedToken.UserIsAdmin === "1")
  } 

  async function GetCloudCTIToken(aTeamsContext)
  {
    let CloudCTIToken = await PushApiGetSignedInToken(mMicrosoftToken)
    if (CloudCTIToken)
    {
      CloudCTIToken = await ValidateTokenExpiration(CloudCTIToken,mMicrosoftToken,aTeamsContext)
    }

    SetCloudCTIToken(CloudCTIToken !== null ? CloudCTIToken : "")
    SetReload(!mReload)
  }

  function SetThemeStyleProperties(aTeamsTheme)
  {
    var r = document.querySelector(':root');

    r.style.setProperty('--background_color', aTeamsTheme.colorNeutralBackground1);
    r.style.setProperty('--background_color_hover', aTeamsTheme.colorNeutralBackground1Hover);
    r.style.setProperty('--font_color', aTeamsTheme.colorNeutralForeground1);
    r.style.setProperty('--font_color_hover', aTeamsTheme.colorNeutralForeground1Hover);
    r.style.setProperty('--font_family', aTeamsTheme.fontFamilyBase);
    r.style.setProperty('--border_color', aTeamsTheme.colorNeutralStroke3);
    r.style.setProperty('--scrollbar_color', aTeamsTheme.colorNeutralForeground4);
    
    //HistoryFilter
    r.style.setProperty('--historyfilter_font_color', aTeamsTheme.colorNeutralForeground2);
    r.style.setProperty('--historyfilter_font_color_hover', aTeamsTheme.colorNeutralForeground2Hover);
    r.style.setProperty('--historyfilter_font_color_focus', aTeamsTheme.colorBrandForegroundLink);    
    r.style.setProperty('--historyfilter_background_color_hover', aTeamsTheme.colorNeutralBackground1Pressed);
    r.style.setProperty('--historyfilter_background_color_focus', aTeamsTheme.colorBrandBackground2);
    r.style.setProperty('--historyfilter_background_color_hoverfocus', aTeamsTheme.colorBrandBackground2Hover);

    //SearchField
    r.style.setProperty('--searchbox_background_color', aTeamsTheme.colorNeutralBackground2);
    r.style.setProperty('--searchbox_background_color_hover', aTeamsTheme.colorNeutralBackground6);
    r.style.setProperty('--searchbox_border_color', aTeamsTheme.colorNeutralStencil2);
    r.style.setProperty('--searchbox_border_color_hover', aTeamsTheme.colorNeutralBackground3Hover);
    r.style.setProperty('--searchbox_border_color_focus', aTeamsTheme.colorNeutralStrokeAccessibleSelected);

    //SectionHeader
    r.style.setProperty('--sectionheader_font_color', aTeamsTheme.colorNeutralForeground2);
    r.style.setProperty('--sectionheader_font_color_focus', aTeamsTheme.colorNeutralForeground2BrandHover);
    r.style.setProperty('--sectionheader_background_color', aTeamsTheme.colorNeutralBackground1);
    r.style.setProperty('--sectionheader_background_color_focus', aTeamsTheme.colorNeutralBackground4Hover);
  }

  try
  {
    const TeamsContext = useTeamsFx()
    try { sessionStorage.setItem("teamstenantid", TeamsContext.context.user.tenant.id) } catch {}
    try { sessionStorage.setItem("teamsUserPrincipalName", TeamsContext.context.user.userPrincipalName) } catch {}

    try { SetThemeStyleProperties(TeamsContext.theme) } catch {}
    
    if (document.URL.endsWith("/logs"))
    {
      return (<Logs Teams={TeamsContext}/>)
    }
    else if (mGetConsent)
    {
      localStorage.setItem("extralog1","mGetConsent start")
      if (cBeta)
      {
        localStorage.setItem("extralog1","mGetConsent beta")
        window.open("https://login.microsoftonline.com/" + TeamsContext.context.user.tenant.id + "/adminconsent?client_id=8555e58a-f808-4b47-bdd7-f0e4cce6527d")
      }
      else
      {
        localStorage.setItem("extralog1","mGetConsent release")
        window.open("https://login.microsoftonline.com/" + TeamsContext.context.user.tenant.id + "/adminconsent?client_id=c7600dc7-90ce-4c8e-8d90-6687b95748e7")
      }
      return <table className="main"><tbody><tr><td align="center" valign="center">{Translate("Waiting for admin consent")}...</td></tr></tbody></table>
    }
    else if (mError !== "")
    {
      //Log(mError,true)
      return <div align="center">{mError}</div>
    }
    else if (TeamsContext.inTeams && TeamsContext.context && TeamsContext.context.user)
    {
      localStorage.setItem("extralog2","inTeams start")
      try { 
        SetLanguage(TeamsContext.context.app.locale) 
      } catch {}

      if (mMicrosoftToken)
      { 
        localStorage.setItem("extralog2","mMicrosoftToken ok")
        if (mCloudCTIToken === null)
        {
          //Log("Retrieving CloudCTI token",false)
          localStorage.setItem("extralog2","GetCloudCTIToken")
          GetCloudCTIToken(TeamsContext)
          localStorage.setItem("extralog2","GetCloudCTIToken ok")

          return <table className="main"><tbody><tr><td align="center" valign="center">Loading...</td></tr></tbody></table>
        }
        else if (mCloudCTIToken === "")
        {
          localStorage.setItem("extralog1","start login")
          return (<Login Teams={TeamsContext} MicrosoftToken={mMicrosoftToken}/>)
        }
        else if (document.URL.endsWith("/logs"))
        {
          return (<Logs Teams={TeamsContext}/>)
        }
        else
        {
          //Log("Loading Main",false)
          localStorage.setItem("extralog2","start main")
          return (<Main Teams={TeamsContext} MicrosoftToken={mMicrosoftToken} SecurityToken={mCloudCTIToken} PhoneType={GetPhoneType(mCloudCTIToken)} UserIsAdmin={GetUserIsAdmin(mCloudCTIToken)} TenantGuid={GetTenantGuid(mCloudCTIToken)} Beta={cBeta}/>)
        }
      }
      else
      {
        return <table className="main"><tbody><tr><td align="center" valign="center">{Translate("Loading")}...</td></tr></tbody></table>
      }
    }
    else
    {
      return <div align="center"><br/><br/><br/>This page can only be viewed as an app within Microsoft Teams</div>
    } 
  }
  catch (error)
  {
    return <table className="main"><tbody><tr><td align="center" valign="center">{error}...</td></tr></tbody></table>
  }
}

export default App