export function DarkBackground(aBackgroundColor) 
{
    try
    {
        const colorString = aBackgroundColor.replace("#","")
        const red = parseInt(colorString.substring(0,2), 16)
        const green = parseInt(colorString.substring(2,4), 16)
        const blue = parseInt(colorString.substring(4,6), 16)
        const luma = 0.2126 * red + 0.7152 * green + 0.0722 * blue; // per ITU-R BT.709
        return (luma < 100) 
    }
    catch
    {
        return false
    }
}