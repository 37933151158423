import React, { useState } from "react"
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'

import { Translate } from "./Translator"

import ImageMakeCall from './ImageMakeCall'
import ActionMultiplePng from '../Assets/action_multiple.png'
import ActionScreenpopPng from '../Assets/action_screenpop.png'
import ActionAddSpeedDialPng from '../Assets/action_addspeeddial.png'

const ContactSpeedDial = (props) => {

    const [mMouseOver, SetMouseOver] = useState(false)

    const ImageUrl = "https://downloadcloudcti.blob.core.windows.net/files/WebRCT/Applications/" + props.Application + ".png"

    const PerformScreenpop = (aScreenpopUrl) => {
        window.open(aScreenpopUrl)
    }

    return (
        <table className="contact" width="100%" onMouseEnter={e => SetMouseOver(true)} onMouseLeave={e => SetMouseOver(false)}>
        <tbody>
            <tr>
                <td width="4"/>
                <td width="40">{props.Application === "" ? <table width="32" height="32" className="unknowncrm"><tbody><tr><td align="center" valign="center">{props.Name ? props.Name[0] : "?"}</td></tr></tbody></table>  : <img alt="" src={ImageUrl} className="applicationpng" width="32" height="32"/>}</td>
                <td width="4"/>
                <td width="*" align="left">{props.Name ? props.Name : Translate("Unknown contact") + ", " + props.Number}</td>
                <td width="10"/>                
                <td width="18">{mMouseOver ? 
                    <Popup trigger={mMouseOver ? <span className="up2px"><img alt="" src={ActionMultiplePng} className="action"/></span> : <span></span>} arrow={false} closeOnDocumentClick={true} position="bottom right">
                    {props.ScreenpopUrl ? <div onClick={() => PerformScreenpop(props.ScreenpopUrl)} className="popupmenuitem"><img alt="" src={ActionScreenpopPng}/><span className="popupmenuitemtext">{props.ScriptName}</span></div> : <span/>}
                    <div onClick={() => props.RemoveFromSpeedDialEvent()} className="popupmenuitem"><span className="popupmenuitemtext">{Translate("Remove from speed dial")}</span></div>
                    </Popup> : 
                    <span/>}</td>
                <td width="10"/>
                <td width="18">{mMouseOver ? <span className="up2px"><ImageMakeCall ContactName={props.Name} ContactNumber={props.Number} MakeCallEvent={() => props.MakeCallEvent(props.Number, props.Name, props.Application, props.ScriptName, props.ScreenpopUrl)}/></span> : <span/>}</td>
                <td width="10"/>
            </tr>
        </tbody>
        </table>
    )
}

ContactSpeedDial.propTypes = {
    Number: PropTypes.string,
    Name: PropTypes.string,
    Application: PropTypes.string,
    ScriptName: PropTypes.string,
    ScreenpopUrl: PropTypes.string,
    TeamsTheme: PropTypes.object,
    MakeCallEvent: PropTypes.func,
    RemoveFromSpeedDialEvent: PropTypes.func
  }

export default ContactSpeedDial;