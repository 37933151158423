import axios from "axios";

const cLookupBaseApi = "https://lookup-va.cloudcti.nl/lookup/api/";

export async function LookupApiSearchName(aSecurityToken, aName) 
{
    const result = await axios.get(
    cLookupBaseApi + "searchname?name=" + aName + '&maxresults=10',  
        {
            headers: {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json"
            }
        })
    return result.data
}