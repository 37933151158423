import React, { useState } from "react"
import PropTypes from 'prop-types'

const HistoryFilter = (props) => {

    return (
        <span className={props.Selected ? "historyfilterselected" : "historyfilter"} onClick={() => props.OnSetCallHistoryFilter()}>{props.Caption}</span>
    )
}

HistoryFilter.propTypes = {
    Caption: PropTypes.string,
    Value: PropTypes.string,
    Selected: PropTypes.bool,
    OnSetCallHistoryFilter: PropTypes.func
  }

export default HistoryFilter