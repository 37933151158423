import React, { useState } from "react"
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button';

import "../css/Login.css";

//let mLogs = []

//export function Log(aMessage,aIsError) 
//{
  //mLogs.push({"Message":aMessage,"IsError":aIsError})    
  //localStorage.setItem("logs",JSON.stringify(mLogs)) 
//}

const Logs = (props) => {

  const mClientToken = localStorage.getItem("clienttoken")
  const mServerToken = localStorage.getItem("servertoken")

  function Clear() 
  {
    localStorage.setItem("clienttoken","")
    localStorage.setItem("servertoken","")
    localStorage.setItem("extralog1","")
    localStorage.setItem("extralog2","")
    localStorage.setItem("extralog3","")
  }   
  
  return (   
    <table className="main">
        <tbody>
            <tr>
                <td align="left">
                    ClientSide token: {mClientToken}
                </td>
            </tr>
            <tr>
                <td align="left">
                    ServerSide token: {mServerToken}
                </td>
            </tr>
            <tr>
                <td align="left">
                    TeamsObject: {JSON.stringify(props.Teams)}
                </td>
            </tr>
            <tr>
                <td align="left">
                    ExtraLog1: {localStorage.getItem("extralog1")}
                </td>
            </tr>
            <tr>
                <td align="left">
                    ExtraLog2: {localStorage.getItem("extralog2")}
                </td>
            </tr>
            <tr>
                <td align="left">
                    ExtraLog3: {localStorage.getItem("extralog3")}
                </td>
            </tr>
            <tr>
                <td align="left">
                    <Button onClick={() => Clear()}>Clear</Button>
                </td>
            </tr>
        </tbody>
    </table>   
    
   
  );
}

Logs.propTypes = {
  Teams: PropTypes.object
}  

export default Logs