import axios from "axios";

const cOAuthBaseApi = "https://auth.cloudcti.nl/api/oauth/getcodeClient";

let mOAuthPollState = ""

export async function OAuthApiStartPolling(aState, aOnAfter, aOAuthWindow)
{
    mOAuthPollState = aState

    let Count = 0
    let Done = false
    while ((mOAuthPollState === aState) && !Done && (Count < 20))
    {
        Count++
        console.log("GetCodeClient state=" + aState + " count=" + Count)
        try 
        {
            await axios.get(cOAuthBaseApi + "?state=" + aState, { timeout: 30000 })
            .then(response => {
                console.log("GetCodeClient state=" + aState + "pollstate=" + mOAuthPollState + " count=" + Count)
                if (mOAuthPollState === aState)
                {
                    console.log("success")
                    try { aOnAfter(true,response.data,aOAuthWindow) } catch {  }
                    Done = true
                }
            })
            .catch(error => {
                if (mOAuthPollState === aState)
                {
                    console.log("busy")
                }
            })
        } 
        catch (ex) 
        {   
            if (mOAuthPollState === aState)
            {
                console.log("error")
                aOnAfter(false,ex,aOAuthWindow)
                Done = true
            }
        }
    }
}

export async function OAuthApiStopPolling()
{
    mOAuthPollState = ""
}