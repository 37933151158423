import axios from "axios";

const cPhoneBaseApi = "https://useraccount.cloudcti.nl/phonejwt/api/";

export async function PhoneApiMakeCall(aSecurityToken,aNumber)
{
    return await axios.post(
        cPhoneBaseApi + "callcontrol/makecall",  
        {
            Number: aNumber
        },
        {
            headers:
            {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json",
                ContentType: "application/json"
            }
        }
    )
}

export async function PhoneApiSubscribe(aSecurityToken)
{
    return await axios.post(
        cPhoneBaseApi + "subscription",  
        {

        },
        {
            headers:
            {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json",
                ContentType: "application/json"
            }
        }
    )
}