export function TranslationsNederlands() 
{
    return [    
        {"id":"Add to speed dial","value":"Aan snelkeuze toevoegen"},
        {"id":"All","value":"Alles"},
        {"id":"An error has occurred","value":"Er is een fout opgetreden"},
        {"id":"An error has occurred while searching","value":"Er is een fout opgetreden tijdens het zoeken"},        
        {"id":"Authorize with Microsoft","value":"Authoriseren met Microsoft"},   
        {"id":"Call","value":"Bellen"},       
        {"id":"Call back","value":"Terugbellen"},   
        {"id":"Copy number","value":"Nummer kopieeren"},  
        {"id":"Configure your CRM","value":"Configureer uw CRM"},                
        {"id":"Email / Phone","value":"Email / Telefoon"},
        {"id":"Enter your CloudCTI credentials","value":"Voer uw CloudCTI credentials in"},
        {"id":"Failed to link to Teams","value":"Kan niet koppelen met Teams"},
        {"id":"History","value":"Geschiedenis"},
        {"id":"Incoming","value":"Inkomend"},    
        {"id":"Loading","value":"Bezig met laden"}, 
        {"id":"More results found. Refine your search","value":"Meer resultaten gevonden. Verfijn uw zoekopdracht"},    
        {"id":"No results found","value":"Geen resultaten gevonden"},  
        {"id":"or","value":"of"},     
        {"id":"Outgoing","value":"Uitgaand"},            
        {"id":"Password","value":"Wachtwoord"},   
        {"id":"Remove from speed dial","value":"Verwijderen uit snelkeuze"},
        {"id":"Remove from view","value":"Verwijderen uit weegave"},     
        {"id":"Search field must contain at least 3 characters","value":"Zoekveld moet ten minste 3 karakters bevatten"},  
        {"id":"Searching","value":"Bezig met zoeken"},      
        {"id":"Search","value":"Zoeken"},          
        {"id":"Sign In","value":"Aanmelden"},  
        {"id":"Sign out","value":"Afmelden"},
        {"id":"Speed dial","value":"Snelkeuze"},
        {"id":"Teams phone API authorization failed","value":"Autoriseren met de Teams telefoon API is mislukt"},
        {"id":"Teams phone API authorization pending","value":"Bezig met autoriseren met de Teams telefoon API"},        
        {"id":"Type a name","value":"Typ een naam"},
        {"id":"Unable to acquire calllist","value":"Kan de gesprekslijst niet opvragen"},
        {"id":"Unable to acquire a subscription","value":"Kan geen abonnement verkrijgen"},
        {"id":"Unable to acquire userdata","value":"Kan de gebruikersgegevens niet opvragen"},
        {"id":"Unknown contact","value":"Onbekend contact"}        
    ]
}