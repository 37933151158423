import axios from "axios";
import { Log } from "./LogApi"

//const cPushApiBase = "https://push-va.cloudcti.nl/pushbeta/";
const cPushApiBase = "https://push-va.cloudcti.nl/pushbeta2/";

const GetDateTimeString = (aDateTime) => {

    const ForceDoubleDigits = (aNumber) => {
        return aNumber < 10 ? ("0" + aNumber) : aNumber
    }

    return aDateTime.getUTCFullYear() + "-" + ForceDoubleDigits(aDateTime.getUTCMonth() + 1) + "-" + ForceDoubleDigits(aDateTime.getUTCDate()) + " " + 
        ForceDoubleDigits(aDateTime.getUTCHours()) + ":" + ForceDoubleDigits(aDateTime.getUTCMinutes()) + ":" + ForceDoubleDigits(aDateTime.getUTCSeconds())
}

export async function PushApiAddCall(aSecurityToken, aNumber, aName, aApplication, aIncoming, aScripts) 
{
    const Now = new Date()
    Now.getUTCMonth()

    await axios.post(
        cPushApiBase + "teams/call",
        {
            "DateTime": GetDateTimeString(new Date()),
            "Number": aNumber,
            "Name": aName,
            "Application": aApplication,
            "Incoming": aIncoming,
            "Scripts": aScripts
        }, 
        {
            headers: {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json",
                ContentType: "application/json"
            }
        }
    )
}

export async function PushApiDeleteCall(aSecurityToken, aId) 
{
    await axios.delete(
        cPushApiBase + "teams/call?id=" + aId,
        {
            headers: {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json",
                ContentType: "application/json"
            }
        }
    )
}

export async function PushApiGetCallList(aSecurityToken, aId) 
{
    try 
    {
      const result = await axios.get(
        cPushApiBase + "teams/calllist?id=" + aId,
        {
            headers: {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json"
            }
        })
      Log("PushApiGetCallList result: " + JSON.stringify(result))
      return result.data
    } 
    catch (ex)
    { 
        return "" 
    }
}

export async function PushApiGetUserData(aSecurityToken) 
{
    try 
    {
      const result = await axios.get(
        cPushApiBase + "teams/userdata",
        {
            headers: {
                Authorization: "Bearer " + aSecurityToken,
                Accept: "application/json"
            }
        })
      Log("PushApiGetUserData result: " + JSON.stringify(result))
      return result.data
    } 
    catch (ex)
    { 
        return "" 
    }
}

export async function PushApiSetUserData(aSecurityToken, aSearchResults, aSpeedDials) 
{
    await axios.put(
    cPushApiBase + "teams/userdata",
    {
        "SearchResults":aSearchResults,
        "SpeedDials": aSpeedDials
    },
    {
        headers: {
            Authorization: "Bearer " + aSecurityToken,
            Accept: "application/json"
        }
    })
}

export async function PushApiGetSignedInToken(aMicrosoftToken) 
{
    try 
    {
        const result = await axios.get(
            cPushApiBase + "teams/signedinas",
            {
                headers: {
                    Authorization: "Bearer " + aMicrosoftToken,
                    Accept: "application/json"
                }
            })
        Log("PushApiGetSignedInToken result: " + JSON.stringify(result))
        return result.data.CloudCTIToken || ""
    } 
    catch
    { 
        return ""
    }
}

export async function PushApiSignIn(aSecurityToken, aTeamsUserId, aTeamsTenantId, aTeamsEmail) 
{
    try 
    {
        const result = await axios.post(
            cPushApiBase + "teams/signin",
            {
                "TeamsUserId": aTeamsUserId,
                "TeamsTenantId": aTeamsTenantId,
                "TeamsEmail": aTeamsEmail
            }, 
            {
                headers: {
                    Authorization: "Bearer " + aSecurityToken,
                    Accept: "application/json",
                    ContentType: "application/json"
                }
            })
        Log("PushApiGetSignedInToken result: " + JSON.stringify(result))
        return ""
    } 
    catch (ex)
    { 
        return ex
    }
}

export async function PushApiSignOut(aMicrosoftToken) 
{
    try 
    {
      await axios.post(
        cPushApiBase + "teams/signout",
        {
        },
        {
            headers: {
                Authorization: "Bearer " + aMicrosoftToken,
                Accept: "application/json"
            }
        })
    } 
    catch { }
}