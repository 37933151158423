import axios from "axios";

const cLogBaseApi = "https://localhost:44321";

export async function Log(aMessage) 
{
    return await axios.post(
        cLogBaseApi + "/api/teamslog",  
        {
            UserPrincipalName: sessionStorage.getItem("teamsUserPrincipalName"),
            Message: aMessage
        },
        {
            headers:
            {
                ContentType: "application/json"
            }
        }
    )
}