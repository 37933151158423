import { TranslationsNederlands } from "./Translations/nederlands.jsx"

let mLocale = ""
let mTranslations = null

export function SetLanguage(aLocale)
{
  if (mLocale !== aLocale)
  {
    if (aLocale.startsWith("nl-"))
    {
      const Translations = TranslationsNederlands()

      mTranslations = {}
      Translations.forEach(translation => {
        mTranslations[translation.id] = translation.value
      })
    }
    mLocale = aLocale
  }
}

export function Translate(aText) 
{
  const result = mTranslations !== null ? mTranslations[aText] : aText
  return (result ? result : aText)
}