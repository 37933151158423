import React, { useState } from "react";
import PropTypes from 'prop-types';

import { DarkBackground } from "./GeneralFunctions"

import FoldedLightBgPng from '../Assets/folded_lightbg.png'
import FoldedDarkBgPng from '../Assets/folded_darkbg.png'
import FoldedHoverPng from '../Assets/folded_hover.png'
import UnfoldedLightBgPng from '../Assets/unfolded_lightbg.png'
import UnfoldedDarkBgPng from '../Assets/unfolded_darkbg.png'
import UnfoldedHoverPng from '../Assets/unfolded_hover.png'

const SectionHeader = (props) => {

    const [mMouseOver, SetMouseOver] = useState(false)

    const mBackgroundColor = props.TeamsTheme.colorNeutralBackground1
     
    return (
        <span className="sectionheader up2px" onMouseEnter={e => SetMouseOver(true)} onMouseLeave={e => SetMouseOver(false)} onClick={props.OnClick}><img alt="" src={props.Folded ? (mMouseOver ? FoldedHoverPng : (DarkBackground(mBackgroundColor) ? FoldedDarkBgPng : FoldedLightBgPng)) : (mMouseOver ? UnfoldedHoverPng : (DarkBackground(mBackgroundColor) ? UnfoldedDarkBgPng : UnfoldedLightBgPng))}/>&nbsp;&nbsp;&nbsp; {props.Text}</span>
    )
}

SectionHeader.propTypes = {
    Folded: PropTypes.bool,
    Text: PropTypes.string,
    TeamsTheme: PropTypes.object,
    OnClick: PropTypes.func
}

export default SectionHeader;