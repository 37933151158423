import React, { useState } from "react";
import PropTypes from 'prop-types';

import ActionScreenpopDarkBgPng from '../Assets/action_screenpop_darkbg.png';
import ActionScreenpopLightBgPng from '../Assets/action_screenpop_lightbg.png';
import ActionScreenpopHoverPng from '../Assets/action_screenpop_hover.png';

import { DarkBackground } from "./GeneralFunctions"

const ImageScreenpop = (props) => {

    const [mMouseOver, SetMouseOver] = useState(false)    

    const mBackgroundColor = props.TeamsTheme.colorNeutralBackground1
    
    const OpenScreenpop = async () => {
        if (props.ScreenpopUrl)
        {
            window.open(props.ScreenpopUrl)
        }
    };
        
    const TextHint = props.ContactName ? (props.ContactName + ", " + props.ScriptName) : props.ScriptName
    return (
        <img alt="" src={mMouseOver ? ActionScreenpopHoverPng : (DarkBackground(mBackgroundColor) ? ActionScreenpopDarkBgPng : ActionScreenpopLightBgPng)} className="action" onMouseEnter={e => SetMouseOver(true)} onMouseLeave={e => SetMouseOver(false)} onClick={() => OpenScreenpop()} width="14" height="14" title={TextHint}/>
    )
}

ImageScreenpop.propTypes = {
    ContactName: PropTypes.string,
    ScriptName: PropTypes.string,
    ScreenpopUrl: PropTypes.string,
    TeamsTheme: PropTypes.object
}

export default ImageScreenpop;