import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Translate } from "./Translator"

const SearchField = (props) => {

    const [mSearchFilter, SetSearchFilter] = useState("")

    return (
        <input autoComplete="new-password" placeholder={Translate("Type a name")} value={mSearchFilter} type="text" className="searchfield" onChange={(event) => { props.OnChanged(); SetSearchFilter(event.target.value) }} onKeyUp={(event) => { if (event.key === "Enter") { props.OnSearchName(event.target.value)}}} />
    )
}

SearchField.propTypes = {
    OnChanged: PropTypes.func, 
    OnSearchName: PropTypes.func
  }

export default SearchField